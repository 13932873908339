module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.jeuchre.org"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-174557777-1"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-react-axe/gatsby-browser.js'),
      options: {"plugins":[],"axeOptions":{},"showInProduction":false},
    },{
      plugin: require('../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"background_color":"#e9e4d9","categories":["games"],"description":"Jeuchre is a card game that you can think of as the anti-Euchre.","display":"minimal-ui","icon":"src/images/gatsby-icon.png","lang":"en-US","name":"Jeuchre","short_name":"Jeuchre","start_url":"/","theme_color":"#034732","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"5989dbe3b491a1c2aa797299f055ccf0"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
